import React, { useEffect, useState } from "react";

import { Block } from "@/blocks/base";
import { BlockContainer } from "@/blocks/base/block-container";
import { renderActions } from "@/blocks/schema-mappers";
import { FlexContainer } from "@/uikit/container";

import { Typography } from "@/uikit/Typography";

import { imgStyle, infoStyle, textStyle, titleStyle } from "./debug.css";
import { RemoteImage } from "@/uikit/remote-image";

const Debug = Block("debug", (props) => {
  const { title, heroImage, mobileImage, actions, border, text } = props;
  const [img, setImg] = useState(heroImage);

  useEffect(() => {
    const handleWindowResize = () => {
      if (window.innerWidth < 768) {
        setImg(mobileImage || heroImage);
      } else {
        setImg(heroImage);
      }
    };

    if (window.innerWidth < 768) {
      setImg(mobileImage || heroImage);
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [heroImage, mobileImage]);

  return (
    <BlockContainer settings={props.settings} defaultTheme="light">
      <FlexContainer direction="column" className={infoStyle} gap="l">
        <Typography.title className={titleStyle}>{title}</Typography.title>
        <Typography settings={{ tag: "p" }} className={textStyle}>
          {text}
        </Typography>
        {actions && renderActions(actions)}
        {img && (
          <RemoteImage
            image={img}
            priority={false}
            className={border ? imgStyle : undefined}
            sizes={{
              mobile: "calc(100vw - 20px)",
              tablet: "calc(100vw - 20px)",
              desktop: "calc(80vw)",
            }}
          />
        )}
      </FlexContainer>
    </BlockContainer>
  );
});

export default Debug;
